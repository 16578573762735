import { render, staticRenderFns } from "./InfoMarkIcon.vue?vue&type=template&id=6fe2e93a&scoped=true"
import script from "./InfoMarkIcon.vue?vue&type=script&lang=js"
export * from "./InfoMarkIcon.vue?vue&type=script&lang=js"
import style0 from "./InfoMarkIcon.vue?vue&type=style&index=0&id=6fe2e93a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe2e93a",
  null
  
)

export default component.exports