<template>
  <el-container class="container subscription-plan">
    <el-card v-if="subscriptionData && billingStatus && usageData">
      <div class="header-title">Subscription Plan</div>

      <div class="form-group row-section"> 
        <el-col class="small">
          <div class="p-lr-25px-mobile">View and manage your subscriptions plans and upgrades</div>
        </el-col>
      </div>

      <div class="form-group row-section">
        <div class="section-caption">
          <label class="section-label section-caption__label text-uppercase">Status</label>
        </div>
        <el-row class="section-row">
          <el-col :span="2" v-if="!isMobile && !loading">
            <div v-if='!subStatusData.has_data'>
              <good-check-mark v-if="active && !usageData.overageBandwidth.size" />
              <exclamation-mark v-if="!active && !suspended" />
              <warning-mark v-if="!suspended && usageData.overageBandwidth.size" />
            </div>
            <div>
              <good-check-mark v-if="subStatusData && subStatusData.data && subStatusData.data.state === 'trial' || subStatusData && subStatusData.data && subStatusData.data.state === 'active'" />
              <warning-mark v-if="subStatusData && subStatusData.data && subStatusData.data.state === 'overdue'" />
            </div>
          </el-col>

          <base-loader :showLoader='loading'/>
          <el-col class="small" :span="20" v-if="usageData && !subStatusData.has_data && !loading">
            <el-row
              color="success"
              :class="{ 'subscription-status-text__active': active, 
                                    'subscription-status-text__warning': !active || usageData.overageBandwidth.size,
                                    text: true}"
            >
              {{active && usageData.overageBandwidth.size === 0
              ? 'Your Subscription is Active'
              : usageData.overageBandwidth.size > 0
              ? 'Your Account requires immediate attention.'
              : 'Your Subscription did not Renew'}}
            </el-row>
            <el-row
              v-if="active && billingStatus.trialExpires && !usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Your 7-day Free Trial will expire in
              <span
                class="text__bold"
              >{{billingStatus.trialExpires}} days.</span>
            </el-row>
            <el-row
              v-if="active && !billingStatus.trialExpires && !usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Your monthly subscription was successfully renewed on
              <span
                class="text__bold"
              >{{lastBillDate}}</span>
            </el-row>
            <el-row
              v-if="active && billingStatus.trialExpires && !usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              You will be billed on
              <span class="text__bold">{{nextBilling}}</span> for the amount of
              <span class="text__bold">${{billingStatus.amount}}</span>
            </el-row>
            <el-row
              v-if="active && !billingStatus.trialExpires && !usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Next billing on
              <span class="text__bold">{{nextBilling}}</span> for the amount of
              <span class="text__bold">${{billingStatus.amount}}</span>
            </el-row>
            <el-row v-if="!active" class="subscription-status-text__margin-bottom">
              Your monthly subscription did not renew on
              <span class="text__bold">{{billingDate}}</span>
            </el-row>
            <el-row v-if="!active && !suspended" class="subscription-status-text__margin-bottom">
              We'll try to rebill your payment method on
              <span class="text__bold">{{nextBilling}}</span> for the amount of
              <span class="text__bold">${{billingStatus.amount}}</span>
            </el-row>
            <el-row
              v-if="!active && !usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Unless we are successful in renewing your account by
              <span
                class="text__bold"
              >{{suspendDate}},</span> your BigComand account may be
              <span class="text__bold">suspended or terminated</span>
            </el-row>
            <el-row
              v-if="active && usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Between
              <span class="text__bold">{{dateToday}}</span> and
              <span class="text__bold">{{nextBilling}}</span> your videos accumulated
              <span
                class="text__bold"
              >{{usageData.overageBandwidth.size}} GB</span> of overage bandwidth at a cost of
              <span
                class="text__bold"
              >${{usageData.overageBandwidth.price}}</span>
            </el-row>
            <el-row
              v-if="active && usageData.overageBandwidth.size"
              class="subscription-status-text__margin-bottom"
            >
              Unless we are successful in collecting the balance of
              <span
                class="text__bold"
              >${{usageData.overageBandwidth.price}}</span> by
              <span class="text__bold">{{suspendDate}},</span> your BigComand account may be
              <span class="text__bold">suspended.</span>
            </el-row>
          </el-col>

          <el-col class='small' :span='20' v-if='subStatusData.has_data && !loading'>
              <div v-if='subStatusData.data.state === "trial" || subStatusData.data.state === "active"'>
                <el-row color="success" class="subscription-status-text__active subscription-status-text__margin-bottom">
                  You Subscription is Active
                </el-row>
                <el-row v-if="subStatusData.data.state === 'trial' && !subStatusData.data.free_plan" class="subscription-status-text__margin-bottom">
                  Your 7-day Free Trial will expire in
                  <span
                    class="text__bold"
                  >{{subStatusData.data.next_dates.days_left}} days.</span>
                </el-row>
                <el-row v-if="subStatusData.data.state === 'active' && !subStatusData.data.free_plan" class="subscription-status-text__margin-bottom">
                  Your {{ subStatusData.data.interval }} subscription was successfully {{ subStatusData.data.life_time_sub ? "billed" : "renewed" }} on
                  <span
                    class="text__bold"
                  >{{subStatusData.data.previous_charge_date}}.</span>
                </el-row>
                <el-row class="subscription-status-text__margin-bottom" v-if='subStatusData.data.next_dates.date && subStatusData.data.next_charge'>
                  You will be billed on
                  <span class="text__bold">{{subStatusData.data.next_dates.date}}</span> for the amount of
                  <span class="text__bold">${{subStatusData.data.next_charge}}</span>
                </el-row>
              </div>
              <div v-if='subStatusData.data.state === "overdue"'>
                <el-row color="success" class="subscription-status-text__warning subscription-status-text__margin-bottom">
                  Your Subscription did not Renew
                </el-row>
                <el-row class="subscription-status-text__margin-bottom subscription-status-text__margin-bottom">
                  Unless we are successful in renewing your account by
                  <span
                    class="text__bold"
                  >{{ subStatusData.data.next_dates.date }},</span> your BigComand account may be
                  <span class="text__bold">suspended or terminated</span>
                </el-row>
              </div>
          </el-col>

          <el-row>
            <el-col :span="2" :offset="!isMobile ? 19 : 5">
              <button
                v-if="!usageData.overageBandwidth.size && active"
                @click="$router.push({ name: 'BillingEstimate', params: { section: 'billing' } })"
                class="btn btn-default btn-primary-outline"
                type="button"
              >See Details</button>

              <!-- <el-button
                v-if="!active || usageData.overageBandwidth.size"
                @click="false"
                type="warning"
                round
              >Update billing information</el-button> -->
            </el-col>
          </el-row>
        </el-row>

        <div class="section-caption">
          <label class="section-label section-caption__label text-uppercase">Usage</label>
        </div>
        <el-row class="section-row" v-if='!loading'>
          <el-col class="medium">
            <label class="section-label">Bandwidth</label>
            <el-row
              class="text"
              color="success"
            >See how much bandwidth you've consumed
              <span v-if='lifetimePlan || subStatusData.data.free_plan'>.</span>
              <span v-else> in this current billing cycle (resets every 30 days). Bandwidth usage outside of the allotted is billed at ${{usageData.overageBandwidth.charge}}</span>
            </el-row>
            <el-row class="text">

              <el-col :span="8" v-if='!lifetimePlan && !subStatusData.data.free_plan'>This month's bandwidth 
                  <span class="col-one__reset-date" v-if='!subStatusData.has_data'>(Resets in {{usageData.usage.mertered_cycle | countDays}} days)</span>
                  <span v-else>(Resets in {{ subStatusData.data.next_dates.days_left }} days)</span>
              </el-col>
              <el-col :span="8" v-else>Bandwidth</el-col>
              <el-col :span="3" :offset="13">
                {{usedBandwidth}} /
                <span class="text__lighter" v-if='!lifetimePlan'>{{formatSize(availableBandwidth, 3, 0)}}</span>
                <span class="text__lighter" v-else>{{ availableBandwidth }}</span>
              </el-col>
            </el-row>
            <el-row class="progress-margin__bottom">
              <el-progress
                :percentage="percentageBandwidth"
                :stroke-width="15"
                :color="customBandwidthColors"
                :show-text="false"
                class="progressbar-square"
              ></el-progress>
            </el-row>

            <label class="section-label">Videos</label>
            <el-row class="text">See how many videos you've stored on Adilo</el-row>
            <el-row class="text">
              <el-col :span="8">Number of videos</el-col>
              <el-col :span="2" :offset="14" v-if="usageData">
                {{usageData.usedVideos}} /
                <span
                  class="text__lighter text-uppercase"
                >{{usageData.availableVideos}}</span>
              </el-col>
            </el-row>
            <el-row class="progress-margin__bottom">
              <el-progress
                :percentage="percentageVideos ? percentageVideos : 0"
                :stroke-width="15"
                :show-text="false"
                :color="customVideosColors"
              ></el-progress>
            </el-row>
            <block-with-bar
              mainHeading="Storage"
              subHeading="Storage size"
              :amountSpent="formatSize(usageData.usage.space_usage, 0, 2)"
              :amountAllowed="usageData.usage.max_storage"
              :percentage="storagePercentLifeTime ? storagePercentLifeTime : countStoragePercents()"
            ></block-with-bar>
            <div v-if='!subStatusData.has_data'>
              <block-with-bar
                mainHeading="Anti-Piracy License"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.anti_piracy} USED`"
                :amountAllowed="`${lifetimePlan  && !lifetimePlanV2 ? 0 : usageData.usage.anti_max} LICENSES`"
                :percentage="countPercents(usageData.usage.anti_piracy,usageData.usage.anti_max)"
                :resetDate="usageData.usage.mertered_cycle | countDays"
              ></block-with-bar>
            </div>
            <div v-else>
              <block-with-bar
                mainHeading="Anti-Piracy License"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.anti_piracy} USED`"
                :amountAllowed="`${lifetimePlan && !lifetimePlanV2 ? 0 : usageData.usage.anti_max} LICENSES`"
                :percentage="countPercents(usageData.usage.anti_piracy,usageData.usage.anti_max)"
                :resetDate="!subStatusData.data.free_plan ? subStatusData.data.next_dates.days_left : false"
              ></block-with-bar>
            </div>
            <div v-if='!subStatusData.has_data'>
              <block-with-bar
                mainHeading="Caption"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.caption_minutes} MINUTES`"
                :amountAllowed="`${lifetimePlan && !lifetimePlanV2 ? 0 : usageData.usage.caption_minute_max} MINUTES`"
                :percentage="countPercents(usageData.usage.caption_minutes,usageData.usage.caption_minute_max)"
                :resetDate="usageData.usage.mertered_cycle | countDays"
              ></block-with-bar>
            </div>
            <div v-else>
              <block-with-bar
                mainHeading="Caption"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.caption_minutes} MINUTES`"
                :amountAllowed="`${lifetimePlan && !lifetimePlanV2 ? 0 : usageData.usage.caption_minute_max} MINUTES`"
                :percentage="countPercents(usageData.usage.caption_minutes,usageData.usage.caption_minute_max)"
                :resetDate="!subStatusData.data.free_plan ? subStatusData.data.next_dates.days_left : false"
              ></block-with-bar>
            </div>

            <div v-if='!subStatusData.has_data'>
              <block-with-bar
                mainHeading="Translation"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.translation_minutes} MINUTES`"
                :amountAllowed="`${lifetimePlan && !lifetimePlanV2 ? 0 : usageData.usage.translation_max} MINUTES`"
                :percentage="countPercents(usageData.usage.translation_minutes,usageData.usage.translation_max)"
                :resetDate="usageData.usage.mertered_cycle | countDays"
              ></block-with-bar>
            </div>
            <div v-else>
              <block-with-bar
                mainHeading="Translation"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.translation_minutes} MINUTES`"
                :amountAllowed="`${lifetimePlan && !lifetimePlanV2 ? 0 : usageData.usage.translation_max} MINUTES`"
                :percentage="countPercents(usageData.usage.translation_minutes,usageData.usage.translation_max)"
                :resetDate="!subStatusData.data.free_plan ? subStatusData.data.next_dates.days_left : false"
              ></block-with-bar>
            </div>
            <!-- <block-with-bar
              mainHeading="Enriched Contacts"
              :subHeading="lifetimePlan ? `` : `This month's usage`"
              :amountSpent="`${usageData.usage.enrich} USED`"
              :amountAllowed="`${lifetimePlan ? 0 : usageData.usage.enrich_max}  ENRICHMENTS`"
              :percentage="countPercents(usageData.usage.enrich,usageData.usage.enrich_max)"
              :resetDate="usageData.usage.mertered_cycle | countDays"
            ></block-with-bar>  -->
            <!-- <block-with-bar
              mainHeading="FORENSIC WATERMARK"
              :subHeading="lifetimePlan ? `` : `This month's usage`"
              :amountSpent="`${usageData.usage.forensic_sessions_count} USED`"
              :amountAllowed="`${lifetimePlan ? 0 : usageData.usage.forensic_max} SESSIONS`"
              :percentage="countPercents(usageData.usage.forensic_sessions_count,usageData.usage.forensic_max)"
              :resetDate="usageData.usage.mertered_cycle | countDays"
            ></block-with-bar> -->

            <div v-if='!subStatusData.has_data'>
              <block-with-bar
                mainHeading="DYNAMIC VISUAL WATERMARK"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.visual_sessions_count} USED`"
                :amountAllowed="`${lifetimePlan ? 0 : usageData.usage.dynamic_visual_max} SESSIONS`"
                :percentage="countPercents(usageData.usage.visual_sessions_count,usageData.usage.dynamic_visual_max)"
                :resetDate="usageData.usage.mertered_cycle | countDays"
              ></block-with-bar>
            </div>
            <div v-else>
              <block-with-bar
                mainHeading="DYNAMIC VISUAL WATERMARK"
                :subHeading="lifetimePlan ? `` : `This month's usage`"
                :amountSpent="`${usageData.usage.visual_sessions_count} USED`"
                :amountAllowed="`${lifetimePlan ? 0 : usageData.usage.dynamic_visual_max} SESSIONS`"
                :percentage="countPercents(usageData.usage.visual_sessions_count,usageData.usage.dynamic_visual_max)"
                :resetDate="!subStatusData.data.free_plan ? subStatusData.data.next_dates.days_left : false"
              ></block-with-bar>
            </div>
          </el-col>
        </el-row>
        <div class="section-caption">
          <label class="section-label section-caption__label text-uppercase">Subscription</label>
        </div>
        <el-row class="section-row__subscription">
          <el-col :span="10">
            <el-row class="subscription-text__bold">{{subscriptionData.plan.name}} Plan</el-row>
            <el-row>Your current plan includes:</el-row>
          </el-col>
          <el-col :span="2" :offset="!isMobile ? subscriptionData.plan.id != 'free' ? 2 : 8 : 0"  v-if="!active || !suspended">
            <div class="d-flex">
              <button
                @click="$router.push({ name: 'ChangeSubscriptionPlan', params: { section: 'billing' } })"
                class="btn btn-default btn-primary-outline mobileFix"
                type="button"
              >Change Subscription</button>
              &nbsp;
              <div v-if='showTip'>
                <Popover message="Coming Soon" top='-40px' />
              </div>
              <!-- <button
                class="btn btn-default btn-primary-outline mobileFix"
                type="button"
                @mouseover='showTip = true'
                @mouseleave='showTip = false'
              >Change Subscription</button> -->
              &nbsp;
              <button
                class="btn btn-default cancel-sub-btn mobileFix"
                type="button"
                @click='subscriptionCancelConfirm = true'
                v-if='subscriptionData.plan.id != "free"'
              >
                <span v-if='loading'>
                  <BaseLoader :showLoader='loading' />
                </span>
                <span v-else>
                  Cancel Subscription
                </span>
              </button>
            </div>
          </el-col>
        </el-row> 
        <el-row 
          v-for="(item, id) in subscriptionData.plan.features"
          :key="item.name"
          :class="{'plan-features__item': true, 'plan-features__expanded': id > 4 && !expanded, 'plan-features__highlighted': item.isHighlighted}"
        >
          <el-col :span="1" class="check-mark" v-if='!lifetimePlan || lifetimePlan && item.name != "Stage"'>
            <check-mark />
          </el-col>
          <el-col :span="23">
            <el-popover
              v-if='!lifetimePlan || lifetimePlan && item.name != "Stage"'
              placement="top"
              :trigger="item.isHighlighted ? 'hover' : 'manual'"
              popper-class="custom_popover popover_box_style pop_top_240x62"
              :content="getDescription(item) ? getDescription(item) : ''"
            >
              <el-row
                slot="reference"
                :class="{'features-first-row': true, 'features-first-row__highlighted': item.isHighlighted}"
              >{{getName(item)}}</el-row>
            </el-popover>
            <el-row
              v-if="item.isDescription"
              class="plan-features__description"
            >{{getDescription(item)}}</el-row>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card
      class="expand-card"
      @click="expandFeatures()"
      v-if="subscriptionData && subscriptionData.plan.features.length > 5"
    >  
      <div class="expand-card__wrapper" 
       @click="expandFeatures()">
        <span v-if="!expanded">See all features of {{subscriptionData.plan.name}} plan</span>
        <span v-else>See less features of {{subscriptionData.plan.name}} plan</span>
      </div>
    </el-card>
    <div v-if='subscriptionCancelConfirm'>
      <BaseConfirm
        head='Cancel Subscription?'
        :body='`This will cancel ${subscriptionData.plan.name} plan.`'
        confirmInput='CANCEL'
        @cancel='subscriptionCancelConfirm = false'
        @confirm='cancelSubscription'
      />
    </div>
    <div v-if='subscriptionUpdateMessages && subscriptionUpdateMessages.length'>
      <BaseMessageBox
        :messages='subscriptionUpdateMessages'
        head="Subscription update"
        @close='subscriptionUpdateMessages = false'
      />
    </div>
  </el-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import GoodCheckMark from "../../Icons/GoodCheckMark";
import CheckMark from "../../Icons/CheckMark";
import ExclamationMark from "../../Icons/ExclamationMark";
import WarningMark from "../../Icons/WarningMark";
import BlockWithBar from "../../../Admin/UsersTab/UserInfo/Usage/BlockWithBar";
import { filterMixin } from "../../../../constants/mixins";
import Popover from '../../../Common/Popover';

export default {
  name: "SubscriptionPlan",
  components: {
    GoodCheckMark,
    CheckMark,
    ExclamationMark,
    WarningMark,
    BlockWithBar,
    Popover
  },
  mixins: [filterMixin],
  computed: {
    user: {
      get() {
        return this.$store.state.userData;
      },
    },
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      }
    },
    subscriptionData() {
      return this.$store.getters.subscriptionData;
    },
    billingStatus() {
      return this.$store.getters.billingStatus;
    },
    usageData() {
      return this.$store.getters.usageData;
    }
  },
  watch: {
    subscriptionData: function() {
      if (this.subscriptionData && !this.subscriptionData.plan.active) {
        this.active = false;
      }
    },

    billingStatus: function() {
      if (this.billingStatus && this.billingStatus.isSuspended) {
        this.suspended = true;
      }
      this.billingDate = moment(this.billingStatus.billingDate).format("LL");
      this.lastBillDate = moment(this.subscriptionData.last_bill_date).format(
        "LL"
      );
      this.suspendDate = moment(this.billingStatus.suspendDate).format("LL");

      this.nextBilling =
        this.billingStatus.trialExpires > 0
          ? moment(this.subscriptionData.subscription.trial_ends_at).format(
              "LL"
            )
          : moment(this.subscriptionData.subscription.ends_at).format("LL");
      console.log(moment(this.subscriptionData.subscription.ends_at)
        .endOf("day")
        .fromNow())
      this.daysToReset = moment(this.subscriptionData.subscription.ends_at)
        .endOf("day")
        .fromNow();
    },

    usageData: function() {
      this.prepareUsageData();
    }
  },
  data: () => ({
    isMobile: false,
    active: true,
    suspended: false,
    billingDate: null,
    nextBilling: null,
    billingAmount: null,
    suspendDate: null,
    daysToReset: null,
    usedBandwidth: null,
    availableBandwidth: null,
    percentageBandwidth: 0,
    percentageVideos: 0,
    lastBillDate:'',
    customBandwidthColors: [
      { color: "#0AD688", percentage: 90 },
      { color: "#F27500", percentage: 99.99 },
      { color: "#EE5951", percentage: 100 }
    ],
    customVideosColors: [
      { color: "#0AD688", percentage: 60 },
      { color: "#F27500", percentage: 99 },
      { color: "#EE5951", percentage: 100 }
    ],
    expanded: false,
    dateToday: "",
    lifetimePlan: true,
    lifetimePlanV2: false,
    storagePercentLifeTime: 0,
    lifetimeFeatures: false,
    subscriptionCancelConfirm: false,
    loading: false,
    subscriptionUpdateMessages: [],
    subStatusData: {has_data: false, data: null},
    showTip: false,
  }),
  beforeDestroy() {},
  beforeMount() {},

  mounted() {
    this.mobileCheck();
    this.getSubscriptionData();
    this.getBillingStatus();
    this.getUsageData();
    this.getSubscriptionStatus();
  },
  created() {},
  methods: {
    getSubscriptionStatus() {
      this.loading = true;
      this.axios.get('payments/subscription/status')
      .then(response => {
        this.loading = false;
        if (response.data.success) {
          this.subStatusData.has_data = response.data.success;
          this.subStatusData.data = response.data.status;
          this.subscriptionUpdateMessages = null;
          this.$forceUpdate();
        } else {
          if (response.data.message) {
            // this.subscriptionUpdateMessages = [response.data.message];
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    getDescription(item) {
      if (!this.lifetimePlan) return item.description;

      if (item.name == "Contacts") {
        return this.lifetimeFeatures.contacts + ' Max';
      } else if (item.name.includes('andwidth')) {
        return this.availableBandwidth + ' Bandwidth';
      } else {
        return item.description;
      }
    },
    getName(item) {
      if (!this.lifetimePlan) return item.name;

      if (item.name.includes('andwidth')) {
        return this.availableBandwidth;
      } else if (item.name.includes('loads')) {
        let name = '';
        if (this.lifetimeFeatures.videos && isNaN(this.lifetimeFeatures.videos) && this.lifetimeFeatures.videos.length) {
          name = this.lifetimeFeatures.videos[0].toUpperCase() + this.lifetimeFeatures.videos.slice(1);
        } else {
          name = this.lifetimeFeatures.videos;
        }
        return name + ' Videos Upload';
      } else {
        return item.name
      }
    },
    expandFeatures() {
      this.expanded = !this.expanded;
    },
    getUsageDataLifetime()
    {
      this.axios.get('restrictions/index')
        .then(response => {
          if (response.data) {

            const data = response.data;
            const lifeTimeData = data.lifetime;
            const content = lifeTimeData.content;

            if (content.length || content.contacts != undefined || content.is_lifetime) {
              this.lifetimePlan = true;
              this.lifetimeFeatures = content;
              if (content.subtitles) {
                this.lifetimePlanV2 = true;
              }
            } else {
              this.lifetimePlan = false;
            }
            let bandWidth = this.converBytesToGb(data.bandwidth_used, true);
            let availableBandwidth = content.bandwidth === 'unlimited' ? 'UNLIMITED' : this.converBytesToGb(content.bandwidth);
            let storage = content.storage === 'Unlimited' ? 'UNLIMITED' : this.converBytesToGb(content.storage);

            this.usedBandwidth = bandWidth['size'] +  ' ' + bandWidth['type'];
            if (availableBandwidth != 'UNLIMITED') {
              this.availableBandwidth = availableBandwidth['size'] +  ' ' + availableBandwidth['type'];
            } else {
              this.availableBandwidth = availableBandwidth;
            }
            if (this.availableBandwidth != 'UNLIMITED') {
              this.percentageBandwidth = (data.bandwidth_used / content.bandwidth) * 100;
              this.percentageBandwidth = this.percentageBandwidth > 100 ? 100 : this.percentageBandwidth;
            } else {
              this.percentageBandwidth = 0;
            }
            this.usageData.availableVideos = content.videos;
            if (this.usageData.availableVideos !== "unlimited") {
              this.percentageVideos = (this.usageData.usedVideos / this.usageData.availableVideos) * 100;
              this.percentageVideos = this.percentageVideos > 100 ? 100 : this.percentageVideos;
            } else {
              this.percentageVideos = 0;
            }

            const maxStorage = storage !== 'UNLIMITED' ? storage['size'] + ' ' + storage['type'] : storage;
            this.usageData.usage.max_storage = maxStorage;
            this.usageData.usage.storage_limit = content.storage;
            this.storagePercentLifeTime = (this.usageData.usage.space_usage / content.storage) * 100;
            this.storagePercentLifeTime = this.storagePercentLifeTime > 100 ? 100 : this.storagePercentLifeTime;
          }
        }).catch(error => {
          console.log(error);
        })
    },
    converBytesToGb(bytes, handleZero = false)
    {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) {
          if (handleZero) {
            return {
              size: 0,
              type: '',
            };
          } else {
            return '0 Byte';
          }
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return {
          size: Math.round(bytes / Math.pow(1024, i), 2),
          type: sizes[i],
        };
    },
    prepareUsageData() {
      const lifeTimePlans = [
        'commercial-paykickstart-static',
        'marketer-paykickstart-static',
        'personal-paykickstart-static',
        'elite-paykickstart-static',
        'adilo-lifetime-business',
        'adilo-lifetime-unlimited-agency',
        'adilo-lifetime-unlimited-agency-split',
        'adilo-lifetime-creator',
        'adilo-lifetime-growth',
        'adilo-lifetime-education',
        'adilo-lifetime-coach',
        'adilo-lifetime-unlimited-elearning',
      ];
      const currentPlanId = this.$store.getters.subscriptionData.plan.id;
      if (
          currentPlanId && currentPlanId.includes('lifetime') ||
          currentPlanId && currentPlanId.includes('static')
        ) {
          this.getUsageDataLifetime();
      } else {

        this.lifetimePlan = false;
        this.usedBandwidth = this.usageData.usedBandwidth.toFixed(2) + " GB";
        this.availableBandwidth = this.usageData.availableBandwidth.toFixed(2);
        this.percentageBandwidth =
          (this.usageData.usedBandwidth / this.usageData.availableBandwidth) *
          100;
        this.percentageBandwidth =
          this.percentageBandwidth > 100 ? 100 : this.percentageBandwidth;
        if (this.usageData.availableVideos !== "Unlimited") {
          this.percentageVideos =
            (this.usageData.usedVideos / this.usageData.availableVideos) * 100;
          this.percentageVideos =
            this.percentageVideos > 100 ? 100 : this.percentageVideos;
        } else {
          this.percentageVideos = 1;
        }
      }
      this.dateToday = moment().format("ll");
    },

    async getSubscriptionData() {
      await this.$store.dispatch("getSubscriptionData");
    },

    async getBillingStatus() {
      await this.$store.dispatch("getBillingStatus");
    },

    async getUsageData() {
      await this.$store.dispatch("getUsageData");
    },
    mobileCheck() {
      let BC_onMobile = false;
      if (
        window.innerWidth <= 1024 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        BC_onMobile = true;
      }

      this.isMobile = BC_onMobile;
      this.$store.commit("setIsMobile", BC_onMobile);
    },
    countStoragePercents() { 
      const inBytes = this.convertGbToBytes(this.usageData.usage.storage_limit);
      if (inBytes) {
        return this.countPercents(this.usageData.usage.space_usage, inBytes);
      }
 
      return 4;
    },
    convertGbToBytes(val) {
      if (val) {
        return val * 1024 * 1024 * 1024;
      } else {
        return null;
      }
    },
    countPercents(used, limit) {
      if (!limit) {
        return 2;
      }
      const percents = (used / limit) * 100;
      if (percents < 100) {
        return percents.toFixed(0);
      }
      return 100;
    },
    async cancelSubscription()
    {
      if (this.loading) return false;
      this.subscriptionCancelConfirm = false;
      this.loading = true;
      await this.axios.post('payments/subscription/update', { type: 'cancel' })
        .then(response => {
          this.loading = false;
          if (response.data.success) {
            this.subscriptionUpdateMessages = ['Subscription cancelled'];
            setTimeout(() => {
              location.reload();
            }, 3000);
          } else {
            if (response.data.errors && response.data.errors.length) {
              this.subscriptionUpdateMessages = response.data.errors;
            }
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding-left: 0;
  max-width: 939px;
  .cancel-sub-btn {
    color: #ee5951;
    border:1px solid #ee5951 !important;
  }
}

.subscription-and-billing {
  position: relative;
}

.subscription-status-text {
  &__active {
    color: #0ad688;
  }

  &__warning {
    color: #ee5951;
  }

  &__margin-bottom {
    margin-bottom: 15px;
  }
}

.section-caption {
  background: #0bacdb07;
  width: 110%;
  margin-left: -40px;
  &__label {
    margin-top: 2rem;
    margin-left: 40px;
  }
}

.section-row {
  padding: 25px 0;
  &__subscription {
    padding: 15px 0;
  }
}

.progress-margin__bottom {
  margin-bottom: 30px;
}

.plan-features {
  &__item {
    border: rgb(241, 248, 253) solid 1px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
  }

  &__expanded {
    display: none;
  }

  &__description {
    font-size: 11px;
    color: #00000069;
  }
}

.check-mark {
  justify-content: center;
  display: flex;
  margin-right: 10px;
}

.features-first-row {
  &__highlighted {
    border-bottom: dashed 1px #00acdc;
    color: #00acdc;
    cursor: pointer;
    width: fit-content;
  }
}

.el-card__body {
  width: 80%;
}

.text {
  white-space: normal;
  line-height: 2rem;
  margin-bottom: 15px;

  &__lighter {
    color: rgb(134, 149, 160);
  }

  &__bold {
    font-weight: 500;
  }
}

.expand-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  height: 64px;
  color: #00acdc;

  &__wrapper {
    cursor: pointer;
  }
}

.subscription-text__bold {
  font-weight: 500;
}
.block-with-bar {
  padding: 0 !important;
  
}
.block-with-bar__row-two {
    width: 100%;
}
.el-progress-bar__inner {
  height: 100% !important;
}
@media (max-width: 960px) {
  .mobileFix {
    font-size: 12px;
    padding: 10px !important;
  }
  
}
.col-one__reset-date{
    color: #637d8e;
  }
</style>

