<template>
    <div class="settings-notification settings-right-area" id="settings-notification" v-if="user">
        <div :class='isMobile ? "d-flex justify-content-between" : ""'>
            <right-header-footer class="header-items m-top-3" :class='isMobile ? "d-flex justify-content-between" : ""'>
                <template v-slot:title>Notifications</template>
            </right-header-footer>
            <MobileMenu :rightArrow='true' :isMobile='isMobile' class='dottedMenu'></MobileMenu>
        </div>
        <el-container id="id_settings-notification" style="height: auto">
            <el-card>
                <div class="header-title">Adilo Notification</div>
                <div
                        class="m-top-1" id="settings-notification-desc-mobile"
                >Choose when you’d like to receive notifications for video-related activities.
                </div>

                <table class='notification-table'>
                    <thead>
                        <tr>
                            <th>NOTIFICATION</th>
                            <th>ACTIVE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class='border-bottom'>
                            <td class='column'>Someone comments on my video</td>
                            <td>
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="changeNotification(1, 'Someone comments on my video')"
                                    active-color="#0AD688"
                                    active-icon-class="el-icon-check success"
                                    v-model="user.settings.comments_my_video"
                                ></el-switch>
                            </td>
                        </tr>
                        <tr>
                            <td class='column border-top'>Bandwidth exceeded</td>
                            <td>
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="changeNotification(5, 'Bandwidth exceeded')"
                                    active-color="#0AD688"
                                    active-icon-class="el-icon-check success"
                                    v-model="user.settings.bandwidth_exceeded"
                                ></el-switch>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <el-row :gutter="20" class="m-top-3 row-border p-lr-25px-mobile">
                    <el-col :span="16" :xs="19">Someone shares my video</el-col>
                    <el-col :span="8" :xs="5">
                        <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeNotification(2, 'Someone shares my video')"
                                active-color="#0AD688"
                                active-icon-class="el-icon-check success"
                                v-model="user.settings.shares_my_video"
                        ></el-switch>
                    </el-col>
                </el-row>

                <el-row :gutter="20" class="m-top-3 row-border p-lr-25px-mobile">
                    <el-col :span="16" :xs="19">Someone downloads my video</el-col>
                    <el-col :span="8" :xs="5">
                        <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeNotification(3, 'Someone downloads my video')"
                                active-color="#0AD688"
                                active-icon-class="el-icon-check success"
                                v-model="user.settings.download_my_video"
                        ></el-switch>
                    </el-col>
                </el-row>

                <el-row :gutter="20" class="m-top-3 row-border p-lr-25px-mobile">
                    <el-col :span="16" :xs="19">New email capture from viewer</el-col>
                    <el-col :span="8" :xs="5">
                        <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeNotification(4, 'New email capture from viewer')"
                                active-color="#0AD688"
                                active-icon-class="el-icon-check success"
                                v-model="user.settings.email_captured"
                        ></el-switch>
                    </el-col>
                </el-row> -->
            </el-card>
        </el-container>
    </div>
</template>

<script>
    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import MobileMenu from './Layout/MobileMenu';

    export default {
        name: "SettingsNotification",
        props: {
            user: Object
        },
        data: () => ({
            isMobile: false
        }),
        created() {
            this.mobileCheck();
        },
        components: {RightHeaderFooter, MobileMenu},
        methods: {
            changeNotification(flag, message) {
                let title = "Notification Disabled";

                if (flag === 1) {
                    title = `Notification ${
                        this.user.settings.comments_my_video
                            ? "Enabled"
                            : "Disabled"
                    }`;
                } else if (flag === 2) {
                    title = `Notification ${
                        this.user.settings.shares_my_video ? "Enabled" : "Disabled"
                    }`;
                } else if (flag === 3) {
                    title = `Notification ${
                        this.user.settings.download_my_video
                            ? "Enabled"
                            : "Disabled"
                    }`;
                } else if (flag === 4) {
                    title = `Notification ${this.user.settings.email_captured ? 'Enabled' : 'Disabled'}`;
                } else if (flag === 5) {
                    title = `Notification ${this.user.settings.bandwidth_exceeded ? 'Enabled' : 'Disabled'}`;
                }

                window.vEvent.fire("settings-common-success", {
                    title: title,
                    message: message,
                    close: false
                });

                this.saveNotification();
            },
            saveNotification() {
                let post_data = {
                    data: {
                        comments_my_video: this.user.settings.comments_my_video,
                        shares_my_video: this.user.settings.shares_my_video,
                        download_my_video: this.user.settings.download_my_video,
                        email_captured: this.user.settings.email_captured,
                        bandwidth_exceeded: this.user.settings.bandwidth_exceeded
                    },
                    url: "/settings/set-notification"
                };

                this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (response.data.result === "success") {
                            this.$store.commit("setUserInfo", this.user);
                        } else {
                        }
                    })
                    .catch(error => {
                    });
            },

            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            }
        }
    };
</script>

<style lang="less" scoped>
    .settings-notification {
        position: relative;

        .row-border {
            padding-bottom: 2rem;
            border-bottom: 1px solid #e8e8ea;
        }
    }

    .notification-table {
        width: 100%;
        thead {
            background-color: rgba(17, 172, 219, 0.02);
            tr th {
                font-family: "Helvetica Neue", serif;
                font-size: 13px;
                font-weight: 300;
                text-align: left;
                padding: 25px 10px 15px 10px;
            }
        }
        tbody {
            tr td {
                font-family: "Helvetica Neue", serif;
                font-size: 1.5rem;
                font-weight: 300;
                text-align: left;
                padding: 15px 10px;
            }
            .border-bottom {
                border-bottom: 1px solid #e8e8ea;
            }
        }
    }

    .dottedMenu {
        margin-top: 15px;
    }

    @media (max-width: 960px) {
        .el-container,
        .is-always-shadow {
            padding: 0px !important;
        }
    }
</style>
