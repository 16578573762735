<template>
  <el-card
    :class="{
      'box-card': true,
      'box-card__plan': true,
      'box-card__free': freePlan,
      'card-first-second': !freePlan,
    }"
    v-if="plan.length"
    :style="cssVars"
  >
    <div
      slot="header"
      :class="{
        clearfix: true,
        'card-header__text': !freePlan,
        'card-header__free': freePlan,
      }"
    >
      <div class="card-header__popular-wrapper" v-if="monthlyPlan.name === 'Pro'">
        <span class="card-header__popular">Popular</span>
      </div>
      <el-col
        :offset="freePlan ? 0 : 2"
        :span="freePlan ? 14 : 20"
        :class="{ 'card-header__free-margin': freePlan }"
      >
        <el-row>
          <span class="card-header__name">{{ monthlyPlan.name }}</span>
        </el-row>

        <el-row>
          <span class="card-header__monthly">${{ monthlyPlan.price }} per month</span>
        </el-row>

        <el-row>
          <span
            v-if="!freePlan"
            class="card-header__annually"
          >or ${{ annualPlan.price }} per month billed annually</span>
          <span v-if="freePlan" class="card-header__annually">Free forever</span>
        </el-row>
      </el-col>
      <el-col :span="4" v-if="freePlan && upgradeFlag !== 'Current'">
        <button
          @click="getProration()"
          class="btn btn-default btn-primary-outline card-button"
          style="border: 1px solid var(--button-border-color) !important;"
          type="button"
        >{{ upgradeFlag }} to this Plan</button>
      </el-col>

      <el-col :span="9" v-if="freePlan && upgradeFlag === 'Current'" class="current-plan__first">
        <div class="current-plan__div"></div>
        <div class="current-plan__first-wrapper">
          <good-black-small-check-mark class="current-plan__svg" />
          <span class="current-plan__span">This is your current plan</span>
        </div>
      </el-col>
    </div>
    <el-row
      class="plus-other__row"
      v-if="
        monthlyPlan &&
        monthlyPlan.name !== 'Starter' &&
        monthlyPlan.name !== 'Free Forever'">
      <div class="plus-other__div"></div>
      <span class="plus-other__span">Plus all other Features of Starter and:</span>
    </el-row>
    <el-row
      v-for="(item, index) in monthlyPlan.features"
      :key="item.name"
      :class="{
        'plan-features__item': true,
        'plan-features__expanded': index > 4 && freePlan && !expandedItems,
      }"
    >
      <el-col :span="freePlan ? 2 : 4" class="check-mark">
        <check-mark :color="freePlan ? '#00ACDC' : monthlyPlan.attributes.color" />
      </el-col>
      <el-col :span="20">
        <el-popover
          placement="top"
          :trigger="item.isHighlighted ? 'hover' : 'manual'"
          popper-class="custom_popover popover_box_style pop_top_240x62"
          :content="item.description ? item.description : ''"
        >
          <el-row
            slot="reference"
            :class="{
              'features-first-row': true,
              'features-first-row__highlighted': item.isHighlighted,
            }"
          >
            {{ item.name }}
            <el-popover
              v-if="
                !item.isDescription && !item.isHighlighted && item.description
              "
              ref="descriptionPop"
              placement="top"
              trigger="hover"
              popper-class="custom_popover popover_box_style pop_top_240x62"
              :content="item.description ? item.description : ''"
            >
              <div
                slot="reference"
                v-if="
                  !item.isDescription && !item.isHighlighted && item.description
                "
                class="features-first-row__info-icon"
              >
                <info-mark-icon />
              </div>
            </el-popover>
          </el-row>
        </el-popover>
        <el-row v-if="item.isDescription" class="features-description">
          {{
          item.description
          }}
        </el-row>
      </el-col>
    </el-row>
    <el-row v-if="!freePlan && upgradeFlag !== 'Current'" class="card-footer-button">
      <button
        :class="{
          btn: true,
          'btn-default': true,
          'btn-primary-outline': true,
          'card-button': true,
          'button-upgrade': upgradeFlag !== 'Downgrade',
        }"
        style="border: 1px solid var(--background) !important;"
        type="button"
        @click="getProration()"
      >{{ upgradeFlag }} to this Plan</button>
    </el-row>

    <el-row v-if="!freePlan && upgradeFlag === 'Current'" class="card-footer-button">
      <div class="current-plan__div"></div>
      <div class="current-plan__wrapper">
        <good-black-small-check-mark class="current-plan__svg" />
        <span class="current-plan__span">This is your current plan</span>
      </div>
    </el-row>
    <el-dialog
      title="CONFIRM UPGRADE"
      :visible.sync="dialogVisible"
      v-if="upgradeFlag === 'Upgrade'"
    >
      <p>
        You are about to upgrade your Adilo Plan from
        {{ subscriptionData.plan.name }} to {{ monthlyPlan.name }}.
      </p>
      <p>Billing Cycle</p>
      <el-radio-group v-model="billingCycle">
        <el-radio label="monthly">
          <span class="bold">Pay monthly (${{ monthlyPlan.price }} billed each month)</span>
        </el-radio>
        <el-radio label="annually">
          <span class="bold">
            Pay annually (${{ annualPlan.price }} x 12)
            <span class="saving">20% Savings</span>
          </span>
        </el-radio>
      </el-radio-group>
      <div v-if="billingCycle === 'monthly'">
        <p>
          We'll apply credit of
          <span class="bold">${{ getFirstBillCredit() }}</span> from your
          current billing cycle. When your plan renews on
          <span
            class="bold"
          >{{ getNextPaymentDate() }}</span> you will be
          charged the full monthly subscription cost of
          <span
            class="bold"
          >${{ monthlyPlan.price }}</span>
        </p>
        <div style="text-align: center;">
          <ul class="list-group-item">
            <li>
              <span>{{ monthlyPlan.name }} Plan Monthly Billing</span>
              <span class="bold">${{ monthlyPlan.price }}</span>
            </li>
            <li>
              <span>Overage from current billing cycle</span>
              <span class="bold">${{prorations.overusage}}</span>
            </li>
            <li>
              <span>Credit from current billing cycle</span>
              <span class="bold green">- ${{ getFirstBillCredit() }}</span>
            </li>
            <li>
              <span>Due today</span>
              <span class="bold">${{ monthlyPlan.price - getFirstBillCredit() }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="billingCycle === 'annually'">
        <p>
          When you subscribe to annual plan you save ${{
          monthlyPlan.price * 12 - annualPlan.price * 12
          }}. Here's a quick breakdown
        </p>
        <div style="text-align: center;">
          <ul class="list-group-item">
            <li>
              <span>{{ annualPlan.name }} Plan Annual Billing</span>
              <div class="d-flex flex-direction-column" style="align-items: flex-end;">
                <span class="bold">${{ annualPlan.price }} x 12 = ${{ annualPlan.price * 12 }}</span>
                <small class="flex">
                  instead of
                  <del>${{ monthlyPlan.price * 12 }}</del>
                </small>
              </div>
            </li>
            <li>
              <span>Overage from current billing cycle</span>
              <span class="bold">${{prorations.overusage}}</span>
            </li>
            <li>
              <span>Credit from current billing cycle</span>
              <span class="bold green">- ${{ getFirstBillCredit() }}</span>
            </li>
            <li>
              <span>Due today</span>
              <span class="bold">${{ annualPlan.price * 12 - getFirstBillCredit() }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="userInfo.exp_year">
        <el-checkbox class v-model="updateAccount">
          <span
            :style="{ color: error ? '#EE5951' : '' }"
          >Upgrade my account and charge my billing method</span>
        </el-checkbox>
        <div class="billing-type-column">
          <div v-if="userInfo.payment_method.toLowerCase() === 'paypal'" class="billing-type">
            <paypal-icon class="billing-icon" />
            <div>Pay with PayPal</div>
          </div>
          <div v-if="userInfo.payment_method.toLowerCase() === 'stripe'" class="billing-type">
            <visa-icon class="billing-icon" />
            <div>
              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
              &bull;&bull;&bull;&bull; {{ userInfo.card_last_four }}
            </div>
          </div>
          <div v-if="userInfo.payment_method.toLowerCase() === 'stripe'" class="billing-card">
            Expires {{ `${userInfo.exp_month}/${userInfo.exp_year}` }}
            <a
              href="/settings/billing/information"
              style="padding-left: 60px;"
            >Edit Payment Information</a>
          </div>
          <div
            v-if="userInfo.payment_method.toLowerCase() === 'paypal'"
            class="billing-email"
          >{{ userInfo.paypal_email }}</div>
        </div>
      </div>
      <div v-if="!userInfo.exp_year">
        <red-warning :text="'Please add billing method to upgrade'"></red-warning>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="userInfo.exp_year">
          <el-button class="custom-btn custom-cancel-btn" @click="dialogVisible = false">Cancel</el-button>
          <el-button
            class="custom-btn custom-confirm-btn"
            type="primary"
            @click="onClickChangePlan($event)"
          >Upgrade to {{ monthlyPlan.name }}</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="CONFIRM DOWNGRADE"
      :visible.sync="dialogVisible"
      v-if="upgradeFlag === 'Downgrade'"
    >
      <p class="bold">
        You are about to downgrade your Adilo Plan from
        {{ subscriptionData.plan.name }} to {{ monthlyPlan.name }}.
      </p>  
      <div
        v-if="!checkTrailDate()">
        <p>
          After downgrading, you will still have access to all Adilo
          {{ subscriptionData.plan.name }} features until
          <br />
          <span class="bold">{{ getNextPaymentDate() }}</span>
          when your Adilo
          {{ monthlyPlan.name }} plan will take effect
        </p>
        <el-checkbox v-model="updateAccount">
          <span class="text-wrap-normal" :style="{ color: error ? '#EE5951' : '' }">
            Downgrade my account to Adilo {{ monthlyPlan.name }}. I'm aware that
            I will no longer be able to use Adilo
            {{ subscriptionData.plan.name }} features
          </span>
        </el-checkbox>
      </div>
      <div
        v-if="checkTrailDate()">
        <p>
          Your account is currently on free trial, if you downgrade now, you
          free trial automatically ends and <b>you’ll be billed a total of ${{ subscriptionData.plan.price }} for
          your Adilo {{ subscriptionData.plan.name }} plan immediately.</b>
          <br />
          <span class="bold">{{ getNextPaymentDate() }}</span>
          when your Adilo
          {{ monthlyPlan.name }} plan will take effect
        </p>
        <div v-if="userInfo.exp_year">
          <el-checkbox class v-model="updateAccount">
            <span
              :style="{ color: error ? '#EE5951' : '' }"
            >Upgrade my account and charge my billing method</span>
          </el-checkbox>
          <div class="billing-type-column">
            <div v-if="userInfo.payment_method.toLowerCase() === 'paypal'" class="billing-type">
              <paypal-icon class="billing-icon" />
              <div>Pay with PayPal</div>
            </div>
            <div v-if="userInfo.payment_method.toLowerCase() === 'stripe'" class="billing-type">
              <visa-icon class="billing-icon" />
              <div>
                &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
                &bull;&bull;&bull;&bull; {{ userInfo.card_last_four }}
              </div>
            </div>
            <div v-if="userInfo.payment_method.toLowerCase() === 'stripe'" class="billing-card">
              Expires {{ `${userInfo.exp_month}/${userInfo.exp_year}` }}
              <a
                href="/settings/billing/information"
                style="padding-left: 60px;"
              >Edit Payment Information</a>
            </div>
            <div
              v-if="userInfo.payment_method.toLowerCase() === 'paypal'"
              class="billing-email"
            >{{ userInfo.paypal_email }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="custom-btn custom-cancel-btn" @click="dialogVisible = false">Cancel</el-button>
        <el-button
          class="custom-btn custom-confirm-btn"
          type="primary"
          @click="onClickChangePlan($event)"
        >Downgrade to {{ monthlyPlan.name }}</el-button>
      </span>
    </el-dialog>

    <!-- Modals -->
    <VideosModal
      :videos="ownerVideos"
      :trigger="videosModal"
      :multiple="true"
      title="SELECT VIDEOS TO KEEP"
      @video_modal="(videosModal = false), $emit('loading-changeplan', false)"
      @selected="videosSelected"
    ></VideosModal>
  </el-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CheckMark from "../../Icons/CheckMark";
import GoodBlackSmallCheckMark from "../../Icons/GoodBlackSmallCheckMark";
import InfoMarkIcon from "../../Icons/InfoMarkIcon";
import { SUBSCRIPTION_API } from "../../../../../config/endpoints";
import PaypalIcon from "../../../Settings/Icons/PaypalIcon";
import VisaIcon from "../../../Settings/Icons/VisaIcon";
import CardIcon from "../../../Settings/Icons/CardIcon";
import BigPaypalIcon from "../../../Settings/Icons/BigPaypalIcon";
import moment from "moment";
import VideosModal from "../../../VideosModal/VideosModal.vue";
import RedWarning from "../../../Admin/UsersTab/UserInfo/Usage/RedWarning";

export default {
  name: "ChangeSubscriptionPlan",
  components: {
    CheckMark,
    GoodBlackSmallCheckMark,
    InfoMarkIcon,
    PaypalIcon,
    VisaIcon,
    CardIcon,
    BigPaypalIcon,
    VideosModal,
    RedWarning
  },
  props: ["plan", "expanded", "ownerVideos"],
  computed: {
    errorsForm: {
      get() {
        return this.$store.state.errorsForm;
      }
    },
    cssVars() {
      return {
        "--background": this.monthlyPlan.attributes
          ? this.monthlyPlan.attributes.color
          : "",
        "--font-color": this.freePlan
          ? "#00ACDC"
          : this.monthlyPlan.attributes
          ? this.monthlyPlan.attributes.color
          : "",
        "--button-border-color": this.freePlan
          ? "#00ACDC"
          : this.monthlyPlan.attributes
          ? this.monthlyPlan.attributes.color
          : ""
      };
    },
    ...mapState({
      subscriptionPlans: state => state.subscriptionPlans,
      subscriptionData: state => state.subscription,
      userInfo: state => state.userData
    })
  },
  data: () => ({
    isMobile: false,
    mark: 1,
    monthlyPlan: {},
    annualPlan: {},
    freePlan: null,
    expandedItems: false,
    dialogVisible: false,
    upgradeFlag: "",
    billingCycle: "monthly",
    tabMonthly: true,
    updateAccount: false,
    videosModal: false,
    error: false,
    prorations:{
      overusage:0,due:0,credit:0
    }
  }),
  watch: {
    plan: function(val) {
      val.forEach(element => {
        if (element.name === "Free Forever") {
          this.freePlan = element;
          this.monthlyPlan = element;
        } else if (element.interval === "yearly") {
          this.annualPlan = element;
        } else if (element.interval === "monthly") {
          this.monthlyPlan = element;
        }
      });

      if (this.monthlyPlan.upgradeFlag === "Current") {
        this.upgradeFlag = "Current";
      } else if (this.monthlyPlan.upgradeFlag === "Downgrade") {
        this.upgradeFlag = "Downgrade";
      } else if (this.monthlyPlan.upgradeFlag === "Upgrade") {
        this.upgradeFlag = "Upgrade";
      }
    },
    expanded: function() {
      this.expandedItems = !this.expandedItems;
    }
  },
  beforeDestroy() {},
  mounted() {
    console.log(this.ownerVideos);
  },
  created() {},
  methods: {
    onClickChangePlan() {
      if (!this.updateAccount) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1000);
        return false;
      }
      if (this.monthlyPlan.id == "free" && this.ownerVideos.length > 10) {
        this.videosModal = true;
        this.dialogVisible = false;
      }
      else this.processSubscription();
    },
    getFirstBillCredit() {
      let now = new Date(this.userInfo.now.date);
      if (this.subscriptionData.subscription.trial_ends_at != null) {
        let trial_ends_at = new Date(
          this.subscriptionData.subscription.trial_ends_at
        );
      } else {
        let trial_ends_at = new Date();
      }
      let trial_ends_at = new Date(
        this.subscriptionData.subscription.trial_ends_at
      );

      let daysInInterval = this.dateDiff(
        this.subscriptionData.subscription.updated_at,
        this.subscriptionData.subscription.ends_at
      );
      let daysRemain = this.dateDiff(
        new Date().toString(),
        this.subscriptionData.subscription.ends_at
      );
      let credit =
        (daysRemain * this.subscriptionData.plan.price) / daysInInterval;
      console.log(this.subscriptionData.subscription);
      console.log(now);
      console.log(this.userInfo.now);
      credit = parseFloat(this.prorations.credit);
      if (trial_ends_at > now) {
        credit = 0;
      }

      return isNaN(credit) ? 0 : credit.toFixed(2);
    },
    checkTrailDate() {
      let trail =  new Date(this.subscriptionData.subscription.trial_ends_at);
      let now =  new Date(this.userInfo.now.date);
      if(trail>now){
        return true
      }
      return false
      
    },
    dateDiff(from, to) {
      return moment(to).diff(moment(from), "days");
    },
    getNextPaymentDate() {
      if (this.billingCycle === "monthly") {
        return moment()
          .add(1, "M")
          .format("MMMM DD, YYYY");
      } else {
        return moment()
          .add(1, "Y")
          .format("MMMM DD, YYYY");
      }
    },
    processSubscription(ids = null) {
      console.log(this.billingCycle);
      console.log(this.annualPlan);

      if (!this.updateAccount) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1000);
        return false;
      }
      this.dialogVisible = false;
      this.$emit("loading-changeplan", true);
      let plans = "";
      if (this.billingCycle == "annually") {
        plans = this.annualPlan.id;
      }
      if (this.billingCycle == "monthly") {
        plans = this.monthlyPlan.id;
      }
      axios
        .put(SUBSCRIPTION_API, { plan_id: plans })
        .then(() => {
          this.$emit("loading-changeplan", false);
          this.$store.dispatch("getSubscriptionData");
          this.$store.dispatch("getSubscriptionPlans");
          if (ids) {
            this.restrict(ids);
          }
        })
        .catch(() => {
          this.$emit("loading-changeplan", false);
          console.warn;
        });
    },
    videosSelected(data) {
      this.videosModal = false;
      let videosToRestrict = this.ownerVideos
        .filter(vid => data.indexOf(vid.id) === -1)
        .map(vid => vid.id);
      this.processSubscription(videosToRestrict);
    },
    restrict(ids) {
      if (ids)
        axios
          .post("restrict-videos", {
            ids: ids
          })
          .then(r => console.log(r));
    },
    getProration(){
      console.log(this.billingCycle)
      let plans = '';
       if (this.billingCycle == "annually") {
        plans = this.annualPlan.id;
      }
      if (this.billingCycle == "monthly") {
        plans = this.monthlyPlan.id;
      }
   
      
         axios
        .post('get_proration', { plan_id: plans })
        .then((data) => {
          console.log(data);
          this.prorations = data.data
          console.log(this.prorations);

          this.dialogVisible = true
        })
        .catch(() => {
          this.$emit("loading-changeplan", false);
          console.warn;
        });
    }
  }
};
</script>

<style lang="less">
.box-card {
  border-radius: 15px;

  &__free {
    border-radius: 15px 15px 0px 0px;
  }
}

.el-card {
  box-shadow: none !important;
  border: none !important;
}

.el-card__header {
  background: var(--background);
  position: relative;
}

.box-card__plan .el-card__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-header {
  &__text {
    color: #ffffff;
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
  }

  &__monthly {
    font-size: 16px;
  }

  &__annually {
    color: #ffffffb7;
  }

  &__popular {
    color: var(--font-color);
  }

  &__popular-wrapper {
    box-shadow: #7f867f 0px 0px 2px 0px;
    position: absolute;
    top: 30px;
    right: -60px;
    background: #ffffff;
    transform: rotate(45deg);
    width: 58%;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
  }

  &__free {
    display: flex;
    align-items: center;
  }

  &__free-margin {
    margin-left: 2%;
  }
}

button.card-button {
  border-radius: 25px;
  color: var(--font-color) !important;
}

.plus-other {
  &__div {
    background: var(--background);
    border-radius: 30px;
    width: 90%;
    height: 25px;
    opacity: 0.05;
  }

  &__row {
    text-align: center;
    position: relative;
    justify-content: center;
    display: flex;
    width: 95%;
  }

  &__span {
    color: var(--font-color);
    opacity: 1;
    position: absolute;
    top: 3px;
    left: 8%;
    font-size: 15px;
  }
}

.plan-features {
  &__item {
    border: rgb(241, 248, 253) solid 1px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 90%;
  }

  &__expanded {
    display: none;
  }
}

.check-mark {
  justify-content: center;
  display: flex;
}

.features-first-row {
  // font-weight: bold;
  max-width: 245px;
  display: flex;
  flex-direction: row;

  &__highlighted {
    border-bottom: dashed 1px var(--font-color);
    color: var(--font-color);
    cursor: pointer;
    width: fit-content;
  }

  &__info-icon {
    margin-left: 5px;
    cursor: pointer;
  }

  &:focus {
    outline: none !important;
  }
}

.features-description {
  max-width: 225px;
  font-size: 11px;
  color: #00000069;
}

.card-footer-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.current-plan {
  &__div {
    background: black;
    border-radius: 30px;
    width: 80%;
    height: 30px;
    opacity: 0.05;
  }

  &__row {
    text-align: center;
    position: relative;
    justify-content: center;
    display: flex;
  }

  &__span {
    font-size: 15px;
  }

  &__first-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 5%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 15%;
  }

  &__first {
    position: relative;
  }

  &__svg {
    margin-right: 10px;
  }
}

button.button-upgrade {
  background: var(--font-color) !important;
  color: #ffffff !important;
}
.el-dialog__wrapper {
  .billing-type-column {
    padding-left: 25px;
    .billing-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;

      .billing-icon {
        margin-right: 20px;
      }
    }
    .billing-email {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .billing-card {
      margin-bottom: 15px;
      font-size: 13px;
    }
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #0f0f0f;
  }
  .el-dialog {
    border: 1px solid #0bacdb;
    border-radius: 10px;
    width: 635px;
    .bold {
      font-weight: bold;
    }
    .green {
      color: #0ad688;
    }
    .text-wrap-normal {
      white-space: normal !important;
    }
    .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid #ebf9fa;
      padding-bottom: 15px;
      .el-dialog__title {
        color: #21455e;
        font-size: 15px;
      }
    }
    .el-dialog__body {
      padding: 15px 45px;
      p {
        color: #21455e;
        font-size: 14px;
      }
      .el-radio-group {
        * {
          outline: none !important;
        }
        margin-top: 5px;
        margin-bottom: 10px;
        .el-radio__inner,
        .el-radio__inner:hover {
          width: 16px;
          height: 16px;
          border: 2px solid #21455e;
        }
        .el-radio__inner::after {
          width: 8px;
          height: 8px;
          background-color: #21455e;
        }
        label.el-radio {
          display: block;
          margin-left: 0 !important;
        }
        .el-radio__input.is-checked .el-radio__inner {
          background: #ffffff;
        }
        .saving {
          color: #f27500;
          margin-left: 50px;
          font-size: 13px;
        }
      }
      .el-radio__label {
        color: #21455e;
        font-size: 14px;
        font-weight: 100;
        padding-left: 18px;
      }
      .list-group-item {
        list-style: none;
        padding-left: 0;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        li {
          display: flex;
          padding: 15px 0;
          justify-content: space-between;
          border-bottom: solid 1px #e8e8ea;
        }
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding: 20px;
    .dialog-footer {
      .custom-btn {
        border-radius: 15px;
        &.custom-cancel-btn {
          background-color: #637c8e;
          color: #ffffff;
        }
        .custom-confirme-btn {
        }
      }
    }
  }
  label.el-checkbox {
    display: flex;
    .el-checkbox__label {
      margin-top: -2px;
    }
  }
}
</style>
