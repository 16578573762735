<template>
    <el-container> 
        <el-card v-if="invoiceDetails" class="billing-receipt">
            <el-row class="receipt-title-row">
                <div class="receipt-id">
                    <span>Receipt {{invoiceDetails.receipt_id}}</span>
                </div>
                <el-button
                    class="admin-receipt-button admin-receipt-button-pdf"
                    @click="downloadPDF(true)"
                    type="info"
                    round
                >Download PDF</el-button>
                <el-button
                    class="admin-receipt-button admin-receipt-button-print"
                    @click="openPdf"
                    type="info"
                    round
                >Print</el-button>
                <el-button
                    class="admin-receipt-button admin-receipt-button-email"
                    @click="sendEmail"
                    type="primary"
                    round
                >Email Receipt</el-button> 
                <el-button v-if="!invoiceDetails.paid && invoiceDetails.status!='paid'"
                    class="admin-receipt-button admin-receipt-button-pay"
                    @click="payInvoice"
                    type="warning"
                    round
                >Pay Invoice</el-button>
            </el-row>
            <el-row>
                <issued-to-billing :issuedTo="invoiceDetails.issuedTo" :span="8" />

                <issued-by-billing :issuedBy="invoiceDetails.issuedBy" />

                <invoice-details :invoice="invoiceDetails" />
            </el-row>

            <el-row class="statement-title">
                <span>Billing Statement</span>
            </el-row>
            <div class="div-hr title-hr-margin"></div>

            <row-billing-estimate
                v-if="subscriptionData"
                :rowText="`${invoiceDetails.plan.name} Subscription`"
                :amount="`$${invoiceDetails.plan.price}`"
                :spanLeftCol="19" 
                :offset="2"
            />
            <div class="div-hr hr-margin"></div>

            <row-billing-estimate
                :rowText="`Adilo Overage Charge`"
                :amount="`$${invoiceDetails.overage_cost}`"
                :spanLeftCol="19"
                :offset="2"
            />
             <div class="div-hr hr-margin"></div>
            <row-billing-estimate
                :rowText="`Adilo Discount`"
                :amount="`$${invoiceDetails.discount}`"
                :spanLeftCol="19"
                :offset="2"
            />
            <div class="div-hr half-top-hr-margin"></div>

            <el-col :span="12" :offset="12">
                <el-row>
                    <el-col :span="18" class="payment-type-row">
                        <el-col
                            v-if="invoiceDetails.provider_id === 'braintree'"
                            :span="4"
                            class="billing-icon"
                        >
                            <paypal-icon />
                        </el-col>
                        <el-col
                            :span="20"
                            :offset="1"
                            v-if="invoiceDetails.provider_id === 'braintree'"
                            class="billing-type"
                        >
                            <div class="text-bold">Paid via PayPal account</div>
                            <div
                                v-if="invoiceDetails.provider_id === 'braintree'"
                                class="billing-email"
                            >{{userInfo.paypal_email}}</div>
                        </el-col>

                        <el-col
                            v-if="invoiceDetails.provider_id === 'stripe'  && invoiceDetails.status === 'paid'"
                            :span="4"
                            class="billing-icon"
                        >
                            <visa-icon />
                        </el-col>
                        <el-col
                            :span="20"
                            :offset="1"
                            v-if="invoiceDetails.provider_id === 'stripe' && invoiceDetails.status === 'paid'"
                            class="billing-type"
                        >
                            <div class="text-bold">Paid via Credit Card</div>
                            <div>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {{userInfo.card_last_four}}</div>
                        </el-col>
                    </el-col>
                    <el-col :span="4" v-if="invoiceDetails.status === 'paid'">
                        <span class="amount-bold amount-font-size">${{invoiceDetails.total}}</span>
                    </el-col>
                </el-row>
                <div class="div-hr hr-margin"></div>
            </el-col>
        </el-card>
        <el-link
            v-if="$route.path.includes('setttings')"
            type="primary"
            class="link-w9"
        >Looking for our W-9?</el-link>
    </el-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import RowBillingEstimate from "../../Settings/Items/Billings/Shared/RowBillingEstimate";
import IssuedToBilling from "../../Settings/Items/Billings/Shared/IssuedToBilling";
import IssuedByBilling from "../../Settings/Items/Billings/Shared/IssuedByBilling";
import InvoiceDetails from "../../Settings/Items/Billings/Shared/InvoiceDetails";
import PaypalIcon from "../../Settings/Icons/PaypalIcon";
import VisaIcon from "../../Settings/Icons/VisaIcon";
import { isEmpty, find } from "lodash";
import axios from 'axios';

export default {
    name: "BillingReceipt",
    components: {
        RowBillingEstimate,
        IssuedToBilling,
        IssuedByBilling,
        InvoiceDetails,
        PaypalIcon,
        VisaIcon
    },
    props: {},
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        },

        ...mapState({
            subscriptionData: state => state.subscription,
            billingInvoices: state => state.billingInvoices,
           // invoiceDetails: state => state.invoiceDetails,
            userInfo: function(state) {
                if (this.$route.path.includes("admin")) {
                    return state.users.userInfo;
                }
                if (this.$route.path.includes("settings")) {
                    return state.userData;
                }
            }
        }),

        bandwidthOverage() {
            return this.billingEstimate &&
                this.billingEstimate.estimate.bandwidthOverage.size
                ? this.billingEstimate.estimate.bandwidthOverage.size.toFixed(2)
                : 0;
        },
        onAdminPage: function() {
            return this.$route.path.includes("admin");
        }
    },
    data: function() {
        return {
            isMobile: false,
            dueToDate: null,
            invoiceDetails:{}
        };
    },
    beforeDestroy() {
        this.linkPDF = null;
    },
    mounted() {
        if (this.onAdminPage && !this.userInfo.settings.city) {
            this.$store.dispatch("getUserInfo", userId);
            this.$store.dispatch("getUserSubscriptionData", userId);
            this.$store
                .dispatch("getUserBillingInvoices", userId)
                .then(() => this.fillInvoiceDetails());
        }
        console.log(this.$route.params)
        this.getReciept(this.$route.params.receiptID);
        
    },
    created() {
        this.dueToDate = moment().format("ll");
        this.getSubscriptionData();
    },
    methods: {
        downloadPDF(download) {
            const userId = this.onAdminPage ? `/${this.$route.params.id}` : "";
            let url =
                process.env.BACKEND_URL +
                "billing/users/invoices/download/" +
                this.invoiceDetails.id +
                userId;

            this.$store
                .dispatch("getData", {
                    url,
                    config: { responseType: "arraybuffer" }
                })
                .then(response => {
                    this.downloadFile(response, "BillingReceipt", download);
                })
                .catch(error => {
                    console.log(error);
                });
        },

        downloadFile(response, filename, download) {
            let newBlob = new Blob([response.data], {
                type: "application/pdf"
            });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            let linkPDF = document.createElement("a");
            linkPDF.href = data;
            download
                ? (linkPDF.download = filename + ".pdf")
                : (linkPDF.target = "_blank");
            linkPDF.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(data);
            }, 100);
        },

        openPdf() {
            this.downloadPDF(false);
        },

        sendEmail() {
            const userId = this.onAdminPage ? `/${this.$route.params.id}` : "";

            let url =
                process.env.BACKEND_URL +
                "billing/invoices/send-email/" +
                this.invoiceDetails.id +
                userId;

            this.$store
                .dispatch("getData", {
                    url
                })
                .then(response => {
                    const event = this.onAdminPage
                        ? "admin-common-success"
                        : "settings-common-success";
                    if (response.data.success === true) {
                        window.vEvent.fire(event, {
                            title: "Email Success!",
                            message: "Email was sent successfully.",
                            close: false
                        });
                    }
                })
                .catch(error => {
                    const event = this.onAdminPage
                        ? "admin-common-error"
                        : "settings-common-error";

                    window.vEvent.fire(event, {
                        title: "Email Failed!",
                        message: "Something went wrong.",
                        close: false
                    });
                    console.log(error);
                });
        },

        payInvoice() {
            let url = "";
            if (this.onAdminPage) {
                url = `/admin/invoice/${this.invoiceDetails.id}/users/${this.$route.params.id}/pay/`;
            } else {
                url = `/billing/invoice/${this.invoiceDetails.id}/pay`;
            }

            this.$store
                .dispatch("post", {
                    url
                })
                .then(response => {
                    if (response.status === 200) {
                        const event = this.onAdminPage
                            ? "admin-common-success"
                            : "settings-common-success";

                        window.vEvent.fire(event, {
                            title: "Paying Success!",
                            message: "Invoice Paid successfully.",
                            close: false
                        });
                    }
                })
                .catch(error => {
                    const event = this.onAdminPage
                        ? "admin-common-error"
                        : "settings-common-error";

                    const message = error.response.data.message.includes("paid")
                        ? error.response.data.message
                        : "Something went wrong.";

                    window.vEvent.fire(event, {
                        title: "Paying Failed!",
                        message,
                        close: true
                    });
                    console.log(error);
                });
        },
        fillInvoiceDetails() {
            const invoiceDetails = find(this.billingInvoices, bill => {
                return bill.receipt_id === this.$route.params.receiptId;
            });
            this.$store.commit("setInvoiceDetails", invoiceDetails);
        },
        getReciept(id){
            console.log(this.invoiceDetails)
            axios.get(`/billing/get_invoice/${id}`)
            .then(res => {
                
                if (res.data) {
                    
                    this.invoiceDetails = res.data;
                    console.log( this.invoiceDetails)
                }
            });

        },
        async getSubscriptionData() {
        await this.$store.dispatch("getSubscriptionData");
        }
    }
};
</script>

<style lang="less" scoped>
.title {
    font-size: 20px;
    font-weight: 400;
}
.div-hr {
    height: 1px;
    border-bottom: 1px solid #e8e8ea;
}

.title-hr-margin {
    margin-top: 25px;
    margin-bottom: 10px;
}

.hr-margin {
    margin: 10px 0;
}

.half-top-hr-margin {
    margin-top: 10px;
    margin-bottom: 20px;
}

.bottom-hr-margin {
    margin-top: 10px;
    margin-bottom: 35px;
}

.link-w9 {
    margin-left: 84%;
}

.el-card {
    margin-bottom: 15px;
}

.amount-bold {
    font-weight: 500;
}

.text-bold {
    font-weight: 500;
    font-size: 16px;
}

.amount-font-size {
    font-size: 20px;
}
</style>
