<template>
    <el-dialog :center="true" :close-on-click-modal="false"
               :top="mobileCheck ? `10vh` : `30vh`"
               :visible.sync="integrationDialog.show"
               :width="mobileCheck ? `100%` : `736px`"
               :height="mobileCheck ? `100%` : `700px`"
               class="new-integration-dialog">
        <template slot="title">
            <el-button @click="goPrev" class="text-left beforeStep" v-show="integrationDialog.step === 2">
                <i class="el-icon-back"></i>
            </el-button>
            NEW INTEGRATION
        </template>
        <!-- NEMESIS ADD START -->
        <el-container v-if="integrationDialog.step === 1">
            <el-row class="integration-step-1">
                <el-col :span="6" :xs="24">
                    <div><br></div>
                </el-col>
                <el-col :span="9" :xs="12">
                    INTEGRATIONS
                </el-col>
                <el-col :span="9" :xs="12">
                    USES
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6" :xs="24">
                    <el-menu :default-active="integrationDialog.active" class="new-integration-category-menu">
                        <el-menu-item @click="selectMenu('all')" index="all">All Integrations</el-menu-item>
                        <el-menu-item @click="selectMenu('email')" index="email">Email</el-menu-item>
                        <el-menu-item @click="selectMenu('webinar')" index="webinar">Webinar</el-menu-item>
                        <el-menu-item @click="selectMenu('crm')" index="crm">CRM</el-menu-item>
                        <el-menu-item @click="selectMenu('social_media')" index="social_media">
                            Social Media
                        </el-menu-item>
                        <el-menu-item @click="selectMenu('other')" index="other">Other</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="18" :xs="24">
                    <div class="m-right-2">
                        <div class="form-group search-group m-left-50 m-right-50">
                            <el-input
                                    class="custom-el-input search-input"
                                    placeholder="Search All Integrations"
                                    v-model="searchIntegration">
                            </el-input>
                            <img alt src="../../../assets/img/stage/icon_search.svg"/>
                        </div>
                        <el-table :data="getFilteredData()"
                                  @current-change="handleCurrentChange"
                                  class="new-integration-table"
                                  :max-height="350"
                                  highlight-current-row ref="mailerListTable"
                                  style="width: 100%; left:-1px"
                                  :show-header="false"
                        >
                            <el-table-column label="INTEGRATIONS" v-if='!mobileCheck'>
                                <el-table-column prop="index" width="280">
                                    <template slot="header" slot-scope="scope"></template>
                                    <template slot-scope="scope">
                                        <div class="w-100 integration-name">
                                            <div class="img-area">
                                                <img :alt="scope.row.index" :src="`${backendUrl}img/Integrations/${getIcon(scope.row.index)}`"/>
                                            </div>
                                            <div class="integration-title">
                                                {{ getName(scope.row.index) }}
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="USES">
                                <el-table-column prop="index">
                                    <template slot="header" slot-scope="scope"></template>
                                    <template slot-scope="scope">
                                        <div class='d-flex'>
                                            <div class="small-img" v-if='mobileCheck'>
                                                <img :alt="scope.row.index" :src="`${backendUrl}img/Integrations/${getIcon(scope.row.index)}`"/>
                                            </div>
                                            <div class='description'>
                                                <div class="integration-title" v-if='mobileCheck'>
                                                    {{ getName(scope.row.index) }}
                                                </div>
                                                <div class="w-100">
                                                    {{ $store.state.integrationLists[scope.row.index].description }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
        </el-container>
        <!-- NEMESIS ADD END -->
        <el-container v-if="integrationDialog.step === 2" class="integration-step-2">
            <div class="form-group row-section">
                <label class="section-label text-uppercase">Display Name</label>
                <div class="control-label m-top-1">
                    The display name is a unique name you’ll use to recognize this integration.
                </div>
                <label class="control-label m-top-3" for="display-name">Display Name</label>
                <el-row>
                    <el-col :span="18" :xs="24">
                        <input :class="{'has-error': (errorsForm.displayNameRequired)}" class="form-control"
                               id="display-name" placeholder="e.g. My main account" type="text" v-model="display_name"/>
                        <label class="text-danger small" v-if="errorsForm.displayNameRequired">
                            <i class="el-icon-warning"></i>
                            Display name is required.
                        </label>
                    </el-col>
                </el-row>
            </div>
            <div class="form-group row-section m-top-4">
                <label class="section-label text-uppercase">Authentication</label>
                <div class="align-items-center integration-description" v-if="selectedIntegration">
                    <img :alt="selectedIntegration.index"
                         :src="`${backendUrl}img/Integrations/${getIcon(selectedIntegration.index)}`"
                         class="m-right-3"/>
                    <div v-if="authIsForm">
                        <div v-if='selectedIntegration.index == "zapier"'>
                            Zapier requires users to add the app into their account to use it.
                            Please click "Accept Invite & Build a Zap" on the new window.
                            You can add app later as well.
                        </div>
                        <div v-else>
                            <div>Integrating Stripe requires the credentials shown below.</div>
                            <div>Enter the each of the credentials and click the button below to integrate.</div>
                        </div>
                    </div>
                    <div v-else>
                        <div>Integrating Google Analytics requires confirmation from within their app.</div>
                        <div>Click the button below to integrate with your Google Analytics account.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row-section m-top-4">
                <div v-if="selectedService.auth === ''">Did not implement this service yet.</div>
                <div v-else-if="authIsForm">
                    <div class="form-group w-100" v-for="(input, i_ind) in selectedService.auth.inputs" :key="`integration_input_${i_ind}`">
                        <label class="control-label">{{ input.name }}</label>
                        <el-input :type="input.type" :placeholder="input.placeholder" v-model="input.model" class="custom-el-input"></el-input>
                    </div>
                    <label class="text-danger small" v-show="selectedService.auth.error">
                        <i class="el-icon-warning"></i>
                        {{ selectedService.auth.errorMessage }}
                    </label>
                </div>
            </div>
        </el-container>
        <div class="dialog-footer" slot="footer">
            <el-button @click="integrationDialog.show = false" round type="light">Cancel</el-button>
            <el-button :disabled="selectedIntegration === null" @click="continueProcess" round type="primary">
                Continue
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import MobileMenu from '../Layout/MobileMenu';
    export default {
        name: "NewIntegration",
        props: {
            integrationDialog: Object,
            user: Object,
            MobileMenu
        },
        data: () => ({
            searchIntegration: "",
            tableData: [
                {index: "aweber", p_index: "email", icon: ''},
                {index: "mailchimp", p_index: "email"},
                {index: "getresponse", p_index: "email"},
                {index: "keap", p_index: "email"},
                {index: "convertkit", p_index: "email"},
                {index: "activecampaign", p_index: "email"},
                {index: "gotowebinar", p_index: "webinar"},
                // {index: "zoom", p_index: "webinar"},
                {index: "webinarjam", p_index: "webinar"},
                // {index: "zoho", p_index: "crm"},
                // {index: "salesforce", p_index: "crm"},
                // {index: "pardot", p_index: "crm"},
                // {index: "marketo", p_index: "crm"},
                // {index: "hubspot", p_index: "crm"},
                // {index: "facebook", p_index: "social_media"},
                // {index: "twitter", p_index: "social_media"},
                // {index: "youtube", p_index: "social_media"},
                // {index: "linkedin", p_index: "social_media"},
                {index: "zapier", p_index: "other"},
                // {index: "vimeo", p_index: "other"},
                // {index: "wistia", p_index: "other"},
                // {index: "googledrive", p_index: "other"},
                // {index: "dropbox", p_index: "other"},
                // {index: "onedrive", p_index: "other"},
            ],
            selectedIntegration: null,
            selectedService: false,
            display_name: "",
            errorsForm: {
                displayNameRequired: false
            },
            freePack: true
        }),
        computed: {
            authIsForm: {
                get() {
                    if (this.selectedService) {
                        let url = this.selectedService.auth;

                        return !(typeof url === "string");
                    }

                    return false;
                }
            },
            mobileCheck: {
                get() {
                    let BC_onMobile = false;
                    if (window.screen.availWidth <= 1024 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        BC_onMobile = true;
                    }

                    return BC_onMobile;
                }
            },
            modalUrl: {
                get() {
                    if (this.selectedService) {
                        let url = this.selectedService.auth;
                        if (typeof url === "string") {
                            return url;
                        } else {
                            return this.selectedService.auth;
                        }
                    }

                    return "#";
                }
            }
        },
        methods: {
            selectedRow(row) {
                this.$refs.mailerListTable.setCurrentRow(row);
            },

            handleCurrentChange(val) {
                if (val && this.$store.state.integrationLists[val.index].auth !== '') {
                    this.selectedIntegration = val;
                } else {
                    this.selectedIntegration = null;
                }
            },

            getName(mailer) {
                return this.$store.state.integrationLists[mailer].name;
            },

            getIcon(mailer) {
                return this.$store.state.integrationLists[mailer].icon;
            },

            selectMenu(index) {
                this.integrationDialog.active = index;
                this.selectedIntegration = null;
            },

            getFilteredData() {
                let data = null;
                if (this.integrationDialog.active === "all") {
                    data = this.tableData.filter(
                        data => !this.searchIntegration || data.index.toLowerCase().includes(this.searchIntegration.toLowerCase())
                    );
                } else {
                    data = this.tableData.filter(
                        data =>
                            (!this.searchIntegration || data.index.toLowerCase().includes(
                                this.searchIntegration.toLowerCase()
                            )) && data.p_index === this.integrationDialog.active
                    );
                }
                if (this.freePack) {
                    data = data.filter(r => r.index == 'aweber' || r.index == 'mailchimp' || r.index == 'gotowebinar');
                }
                return data;
            },

            continueProcess() {
                this.errorsForm.displayNameRequired = false;

                if (this.integrationDialog.step === 1) {
                    this.integrationDialog.step = 2;
                    this.display_name = "";
                    this.selectedService = this.$store.state.integrationLists[this.selectedIntegration.index];
                } else {
                    if (this.display_name === "") {
                        this.errorsForm.displayNameRequired = true;
                        return false;
                    }
                
                    if (!this.authIsForm && this.selectedService.auth !== "") {
                        window.Ls.remove('integration-active');

                        let open_url = `${this.selectedService.auth}?display_name=${this.display_name}&BCAccessToken=${window.BCookie.get('BCAccessToken')}`;

                        let new_window = window.open(open_url, '_blank', "width=700px,height=700px");
                        let timer = setInterval(() => {
                            if (new_window.closed) {
                                clearInterval(timer);
                                if (window.Ls.get('integration-active')) {
                                    let active_status = window.Ls.get('integration-active');
                                    if (active_status === 'true') {
                                        this.successConnect(true);
                                    } else {
                                        window.vEvent.fire('settings-common-error', {
                                            title: 'Cannot Continue',
                                            message: 'Please check and fix the errors',
                                            close: false
                                        });
                                    }
                                } else {
                                    this.successConnect(false);
                                }
                            }
                        }, 200);
                    } else {
                        if (this.selectedService.auth !== '') {
                            let data = {};
                            this.selectedService.auth.inputs.map((el) => {
                                data[el.key] = el.model;
                            });

                            data.display_name = this.display_name;

                            let post_data = {
                                url: this.selectedService.auth.action,
                                data: data
                            };

                            this.selectedService.auth.error = false;
                            this.selectedService.auth.errorMessage = '';

                            this.$store.dispatch('post', {...post_data}).then((r) => {
                                if (r.data.result === 'success') {
                                    this.successConnect(true);
                                } else {
                                    this.selectedService.auth.error = true;
                                    this.selectedService.auth.errorMessage = r.data.message;
                                    this.$forceUpdate();
                                }
                            }).catch(e => {
                                alert('failing');
                                this.selectedService.auth.error = true;
                                this.selectedService.auth.errorMessage = 'Invalid Credentials';
                                this.$forceUpdate();
                            });
                        }
                    }
                }
            },
            successConnect(flag) {
                if (flag) {
                    window.vEvent.fire('settings-common-success', {
                        title: 'Connection Successful',
                        message: this.display_name,
                        close: false
                    });
                }
                window.vEvent.fire('load-integration-list');
                window.vEvent.fire('load-integration-refresh', this.selectedIntegration.index);
                this.integrationDialog.show = false;
                if (this.selectedIntegration.index == "zapier") window.open('https://zapier.com/developer/public-invite/198419/5d19742fc7a48fb09480d95d017f2c60/', '_blank', "width=700px,height=700px"); 
            },
            goPrev() {
                this.integrationDialog.step = 1;
                this.selectedRow(this.selectedIntegration);
            },
            getPlan()
            {
                this.axios.post('owner-plan').then(r => {
                    if (r && r.data) {
                        this.freePack = r.data.name == 'Free Forever';
                    }
                });
            }
        },
        mounted()
        {
            this.getPlan();
        },
    };
</script>

<style lang="less">
    .new-integration-dialog {
        //NEMESIS ADD START
        .el-dialog {
            border-radius: 10px;
        }
        .el-dialog__header{
            font-size: 15px;
            font-weight: 500;
        }
        //NEMESIS ADD END
        .el-dialog__body {
            padding: 10px 0 5px;//NEMESIS CHANGE padding: 20px 20px 5px;
            //NEMESIS ADD START
            table.el-table__header {
                div.cell{
                    font-size: 13px;
                    color: var(--color-content);
                    font-weight: 500 !important;
                    text-align: left;
                }
            }
            table {
                tbody {
                    font-size: 14px;
                    color: var(--color-content);
                }
            }
            //NEMESIS ADD END
            .new-integration-category-menu {
                .el-menu-item {
                    height: 38px;       //NEMESIS CHANGE height: 35px;
                    line-height: 38px;  //NEMESIS CHANGE line-height: 35px;

                    &:first-child {
                        margin-bottom: 24px;//NEMESIS CHANGE margin-bottom: 2rem;
                    }
                }
            }

            .integration-step-2 {
                padding: 20px 20px 0;
            }
        }

        .beforeStep {
            position: absolute;
            left: -10px;
            border: 0;
            padding-top: 0 !important;
            background: transparent !important;
            font-size: 18px;
        }

        .el-dialog__footer {
            padding: 10px 20px 26px;
        }
    }

    @media (max-width: 960px) {
        .new-integration-category-menu {
            display: flex;
            overflow: auto;
        }

        .integration-step-1 {
            display: none;
        }

        .el-table .cell {
            width: max-content;
        }

        .el-table th.is-leaf, .el-table td {
            border-bottom: none;
        }

        .el-table--enable-row-hover .el-table__body tr:hover > td {
            width: max-content;
        }

        .integrationLeftSide {
            display: none !important;
        }

        .small-img {
            width: 90px;
            height: 100px;
        }
        .small-img img {
            height: 40px;
            width: 60px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .description {
            position: relative;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
</style>

<!-- NEMESIS START -->
<style lang="less" scoped>
    @import "~element-ui/lib/theme-chalk/input.css";
</style>
<!-- NEMESIS END -->